;(function(window, document, $) {
	var $win = $(window);
	var $doc = $(document);
	var index = 0;
	var group = 1;
	var initSM;
	var headerH;
	var activeClass = 'active';
	var $btnFontSizeDecrease = $('.jsDecreaseFont');
	var $btnFontSizeIncrease = $('.jsIncreaseFont');
	var font_size_step = 1;
	var max_font_size = 20;
	var min_font_size = 16;
	var $isoGrid = $('.isotope-grid');
	var iso;
    var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    var is_Edge = navigator.userAgent.indexOf("Edge") > -1;
    var is_chrome = !!window.chrome && !is_opera && !is_Edge;
    var is_explorer = typeof document !== 'undefined' && !!document.documentMode && !is_Edge;
    var is_firefox = typeof window.InstallTrigger !== 'undefined';
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


	// Font Sizing
	function setFontSize($element, amount) {
		if (!$element.length) {
			return;
		}
		var currentFontSize = parseInt($element.css('font-size').replace('px', ''), 10);
		var newFontSize = currentFontSize + amount;

		if (newFontSize < min_font_size || newFontSize > max_font_size) {
			return;
		}
		$element.css('font-size', newFontSize);
	}

	// Decrease font size
	$btnFontSizeIncrease.on('click', function(e) {
		e.preventDefault();

		setFontSize($('.jsTextSizing > p'), font_size_step);
		setFontSize($('.jsTextSizing > h2'), font_size_step);
	});

	// Increase font size
	$btnFontSizeDecrease.on('click', function(e) {
		e.preventDefault();

		setFontSize($('.jsTextSizing > p'), -1 * font_size_step);
		setFontSize($('.jsTextSizing > h2'), -1 * font_size_step);
	});

	function mobileView() {
		return $win.outerWidth() < 768;
	}
	function tabletView() {
		return $win.outerWidth() < 1199;
	}

	// Fixed header - Back up effect

	var didScroll;
	var delta = 5;
	var lastScrollTop = 0;

	function hasScrolled() {
		var st = $win.scrollTop();
		headerH = $('.header').outerHeight();

		if ($('.jsTimeline').length) {
			if (!$('body').hasClass('nav-timeline-bottom')) {
				if(mobileView()){
					if($win.scrollTop() > 100
						&& !$('body').hasClass('nav-timeline-fixed')) {
						$('body').removeClass('header-down').addClass('header-up');
						return;
					}
				} else {
					if($win.scrollTop() > $('.jsToggleContainer').offset().top
						&& !$('body').hasClass('nav-timeline-fixed')) {
						$('body').removeClass('header-down').addClass('header-up');
						return;
					}
				}
			}
			if($win.scrollTop() > $('.jsToggleContainer').offset().top) {
				if(($win.height() + $win.scrollTop())
					> ($('.jsToggleContainer').offset().top + $('.jsToggleContainer').outerHeight() + 100)
					&& $('body').hasClass('nav-timeline-fixed')) {

					$('body').removeClass('header-up').addClass('header-down');
				}
			}
		}
		if ($('body').hasClass('nav-timeline-fixed') || $('body').hasClass('timeline-scroll')) {
			$('body').removeClass('header-down').addClass('header-up');
			return;
		}

		if ($('.article-large').hasClass('list-actions-fixed')) {
			$('body').removeClass('header-down').addClass('header-up');
			return;
		}
		if ($('body').hasClass('nav-opened')) {
			return;
		}
		if (st == 0) {
			$('body').removeClass('header-up').addClass('header-down');
		}
		if(Math.abs(lastScrollTop - st) <= delta) {
			return;
		}

		if (st > lastScrollTop && st > headerH){
			// Scroll Down
			setTimeout(function() {
				$('body').removeClass('header-down').addClass('header-up');
			}, 300);
		} else {
			// Scroll Up
			if( st + $win.height() < $doc.height() ) {
				setTimeout(function() {
					$('body').removeClass('header-up').addClass('header-down');
				}, 300);
			}
		}

		lastScrollTop = st;
	}

	// Fixed Header

	function fixedHeader() {
		var isScrolling = $win.scrollTop() > 50;

		if (mobileView()){
			isScrolling = $win.scrollTop() > 0;
		}
		if (isScrolling) {
			$('body').addClass('header-fixed');
		} else {
			$('body').removeClass('header-fixed');
		}

		if (isScrolling) {
			hasScrolled();
		}
	}

    // Sliders
    function initSliders() {

		$('.slider-intro').each(function(){
			var $slider = $(this);
			var $sliderSlides = $slider.find('.slider__slides');

			var imageTranslate = $(this).attr('data-imagetranslate');
			var leftDirectionFlag = false;

			var startSeconds = 0;
			var stopSeconds = 0;
			var secondsLeft = 5000;
			var leavingTime = 0;
			var elapsedTime = 0;
			var realTime = 0;
			var cummulativeTime = 0;
			var timer;

			function mouseenteringSlider() {
				var d = new Date();
				var stoppedTime = d.getTime();

				stopSeconds = d.getTime();
				secondsLeft = 5000;
				triggerSlideChange = 300000;

				elapsedTime = stoppedTime - startSeconds - leavingTime;

				timeLeft();
			}
			function startingTimeOfSlide() {
				var date = new Date();
				var startTimeOfSlide = date.getTime();

				startSeconds = 0;
				stopSeconds = 0;
				secondsLeft = 5000;
				leavingTime = 0;
				elapsedTime = 0;
				realTime = 0;
				cummulativeTime = 0;
				startSeconds = startTimeOfSlide;
			}
			function timeLeft() {
				realTime = elapsedTime;

				cummulativeTime = cummulativeTime + realTime;
			}
			function mouseleavingSlider() {
				var d = new Date();
				leavingTime = d.getTime();

				startSeconds = 0;

				secondsLeft = 5000 - cummulativeTime;
			}

			function Timer(callback, delay) {
			    var timerId, start, remaining = delay;

			    this.pause = function() {
			        window.clearTimeout(timerId);
			        remaining -= Date.now() - start;
			    };

			    this.resume = function() {
			        start = Date.now();
			        window.clearTimeout(timerId);
			        timerId = window.setTimeout(callback, remaining);
			    };
			    this.stop = function() {
			        window.clearTimeout(timerId);
			        return;
			    }

			    this.resume();
			}

			function backwardSlideshow(prev) {
				timer = new Timer(function() {
					$sliderSlides.trigger('to.owl.carousel', prev);
				}, 5000);
				// timer.pause();
				// timer.resume();

				$('.slider-intro .owl-stage-outer').on('mouseenter', function() {
					timer.pause();
				});
				$('.slider-intro .owl-stage-outer').on('mousemove', function() {
					timer.pause();
				});
				$doc.on('click', '.owl-prev, .owl-next', function() {
					timer.pause();
				});

				if ($slider.hasClass('first-slide')) {
					return;
				}
			}

			$slider.addClass('slideshow');

			$sliderSlides
				.addClass('owl-carousel')
				.owlCarousel({
					nav: true,
					loop: false,
					navText: ['<i class="ico-arrow-white-left"></i>', '<i class="ico-arrow-white"></i>'],
					navContainer: '.slider-intro .slider__arrows',
					autoplay: true,
					autoPlaySpeed: 5000,
					autoPlayTimeout: 5000,
					autoplayHoverPause: true,
					autoplayHoverPause: false,
					smartSpeed: 1500,
					mouseDrag: false,
					touchDrag: false,
					responsive: {
						0: { items: 1 },
						768: { items: 1, mouseDrag: false, touchDrag: false },
						1200: {
							touchDrag: false,
							mouseDrag: false,
							items: 1
						}
					}
				}).on('changed.owl.carousel', function(elem) {
					var currentSlide = elem.item.index;
					var previousSlide = currentSlide - 1;

					if (currentSlide == $('.slider-intro .owl-item').length - 1) {
						$slider.addClass('last-slide backwards-slideshow');
					} else {
						$slider.removeClass('last-slide');
					}
					if (currentSlide == 0) {
						$slider.addClass('first-slide').removeClass('backwards-slideshow');
					} else {
						$slider.removeClass('first-slide');
					}


					$('.slider-intro .owl-prev').add('.slider-intro .owl-next').css('pointer-events', 'none');

					$('.slider-intro .owl-item').add($slider).removeClass('pause-timer resume-timer');
					$slider.removeClass('slideshow');
					$sliderSlides.trigger('stop.owl.autoplay');

				    $sliderSlides.data('owl.carousel').settings.autoplay = false;
				    $sliderSlides.data('owl.carousel').options.autoplay = false;

					$('.slider-intro .owl-item.active .slider__slide-entry')
						.addClass('animate-out');

					$('.slider-intro .slider__slide-entry')
						.removeClass('in-view')
						.css('transition-delay', '0s')
						.attr('data-type', 'slide-in');

					if ($slider.hasClass('backwards-slideshow')) {
					    setTimeout(function() {
							startSeconds = 0;
							stopSeconds = 0;
							secondsLeft = 5000;
							leavingTime = 0;
							elapsedTime = 0;
							realTime = 0;
							cummulativeTime = 0;

							$sliderSlides.trigger('stop.owl.autoplay');

						    $sliderSlides.data('owl.carousel').settings.autoplay = false;
						    $sliderSlides.data('owl.carousel').options.autoplay = false;

						    startingTimeOfSlide();
						    backwardSlideshow(previousSlide);

						    $slider.addClass('slideshow');

						    translateSlideImages();
					    }, 20);
					} else {
					    setTimeout(function() {
							startSeconds = 0;
							stopSeconds = 0;
							secondsLeft = 5000;
							leavingTime = 0;
							elapsedTime = 0;
							realTime = 0;
							cummulativeTime = 0;

							$sliderSlides.trigger('play.owl.autoplay', [5000]);

						    $sliderSlides.data('owl.carousel').settings.autoplay = true;
						    $sliderSlides.data('owl.carousel').options.autoplay = true;

						    startingTimeOfSlide();

						    $slider.addClass('slideshow');

						    translateSlideImages();
					    }, 20);
					}


					setTimeout(function() {
						$('.slider-intro .owl-item.active .slider__slide-entry')
								.removeClass('animate-out');

						$('.slider-intro .owl-item.active')
							.next()
								.find('.slider__slide-entry')
								.removeClass('in-view');

						$('.slider-intro .owl-item.active .slider__slide-entry').addClass('in-view').css('transition-delay', '0s');
					}, 500);

					setTimeout(function() {
						$('.slider-intro .owl-item:not(.active) .slider__slide-entry')
							.removeClass('animate-out');
					}, 1000);
					setTimeout(function() {
						$('.slider-intro .owl-prev').add('.slider-intro .owl-next').css('pointer-events', 'all');
					}, 1500);
				});

				setTimeout(function() {
					$('.slider-intro .owl-item.active')
						.next().find('.slider__slide-entry')
						.removeClass('in-view');

					$('.slider-intro .owl-item.active')
						.next()
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
						});
				}, 500);

				function translateSlideImages() {
					$('.slider-intro .owl-item.active')
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(0px, 0px, 0px)',
							'transition': '1480ms',
						});

					$('.slider-intro .owl-item.active')
						.prev()
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(' + imageTranslate + 'px, 0px, 0px)',
							'transition': '1480ms',
						});

					$('.slider-intro .owl-item.active')
						.next()
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
							'transition': '1480ms',
						});
				}

				startingTimeOfSlide();

				$('.slider-intro .owl-stage-outer').on('mouseenter', function() {
					if (!tabletView()) {
						$('.owl-item.active').add($slider).addClass('pause-timer').removeClass('resume-timer');
						$sliderSlides.trigger('stop.owl.autoplay');

					    $sliderSlides.data('owl.carousel').settings.autoplay = false;
					    $sliderSlides.data('owl.carousel').options.autoplay = false;

					    mouseenteringSlider();
					}
				});

				$('.slider-intro .owl-stage-outer').on('mousemove', function() {
					if (!tabletView()) {
						$('.owl-item.active').add($slider).addClass('pause-timer').removeClass('resume-timer');

						$sliderSlides.trigger('stop.owl.autoplay');

					    $sliderSlides.data('owl.carousel').settings.autoplay = false;
					    $sliderSlides.data('owl.carousel').options.autoplay = false;
					}
				});

				$('.slider-intro .owl-stage-outer').on('mouseleave', function() {
					var prevSlide = $('.slider-intro .owl-item.active').index() - 1;
					// var nextSlide = $('.owl-item.active').index() + 1;

					$('.slider-intro .owl-item.active').add($slider).addClass('resume-timer').removeClass('pause-timer');

					mouseleavingSlider();

					if (secondsLeft <= 100) {
						if ($slider.hasClass('backwards-slideshow')) {
					    	$('.owl-prev').trigger('click');
						} else {
					    	$sliderSlides.trigger('to.owl.carousel', prevSlide);
						}
					} else {
						if ($slider.hasClass('backwards-slideshow')) {

							$sliderSlides.trigger('stop.owl.autoplay');
						    $sliderSlides.data('owl.carousel').settings.autoplay = false;
						    $sliderSlides.data('owl.carousel').options.autoplay = false;

						    setTimeout(function() {
						    	$sliderSlides.trigger('to.owl.carousel', prevSlide);
						    }, secondsLeft);
						} else {
							$sliderSlides.trigger('play.owl.autoplay', [secondsLeft]);

						    $sliderSlides.data('owl.carousel').settings.autoplay = true;
						    $sliderSlides.data('owl.carousel').options.autoplay = true;
						}
					}
				});
		});


		$('.slider-entries').each(function(){
			var $slider = $(this);
			var $sliderMedia = $slider.closest('.section-slider').find('.slider-media .slider__slides');
			var $sliderSlides = $slider.find('.slider__slides');
			var sliderId = $slider.find('.slider-controls').attr('id');

			$sliderSlides
				.addClass('owl-carousel')
				.owlCarousel({
					nav: true,
					loop: false,
					navText: ['<i class="ico-arrow-dark-left"></i>', '<i class="ico-arrow-dark"></i>'],
					navContainer: '#' + sliderId + ' .slider__arrows',
					autoplay: false,
					autoPlaySpeed: 5000,
					autoPlayTimeout: 5000,
					autoplayHoverPause: true,
					autoplayHoverPause: false,
					smartSpeed: 1500,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					responsive: {
						0: { items: 1, mouseDrag: false, touchDrag: false },
						768: { items: 1, mouseDrag: false, touchDrag: false },
						1200: { items: 1, mouseDrag: false, touchDrag: false }
					}
				}).on('changed.owl.carousel', function(elem) {
					$slider.find('.owl-prev').add($slider.find('.owl-next')).css('pointer-events', 'none');

					$slider.find('.owl-item.active .article__content')
						.addClass('animate-out');

					$slider.find('.article__content')
						.removeClass('in-view')
						.addClass('js-reveal-content')
						.attr('data-type', 'slide-in')
						.css('transition-delay', '0s');

					setTimeout(function() {
						$slider.find('.article__content').addClass('in-view');
						$slider.find('.article__content > div').addClass('in-view').css('transition-delay', '0s');

						$slider.find('.owl-item.active .article__content')
							.removeClass('animate-out');

						$slider.find('.owl-item.active')
							.next()
								.find('.article__content')
								.removeClass('in-view');

						$slider.find('.owl-item.active .article__content')
							.addClass('in-view').css('transition-delay', '0s');
					}, 500);

					setTimeout(function() {
						$slider.find('.owl-prev:not(.disabled)')
							.add($slider.find('.owl-next:not(.disabled)')).css('pointer-events', 'all');
					}, 1200);
				});

				// $sliderSlides.trigger('to.owl.carousel', 0);

				$slider.find('.owl-next').on('click', function() {
					$slider.closest('.section-slider')
						.find('.slider-media .slider__slides')
						.trigger('next.owl.carousel');
				});

				$slider.find('.owl-prev').on('click', function(e) {
					$slider.closest('.section-slider')
						.find('.slider-media .slider__slides')
						.trigger('prev.owl.carousel');
				});
		});

		$('.slider-media').each(function(){
			var $slider = $(this);
			var $sliderSlides = $slider.find('.slider__slides');
			var imageTranslate = $slider.attr('data-imagetranslate');

			$sliderSlides
				.addClass('owl-carousel')
				.owlCarousel({
					nav: false,
					loop: false,
					autoplay: false,
					autoPlaySpeed: 5000,
					autoPlayTimeout: 5000,
					autoplayHoverPause: true,
					autoplayHoverPause: false,
					smartSpeed: 1500,
					responsive: {
						0: { items: 1, mouseDrag: false, touchDrag: false },
						768: { items: 1, mouseDrag: false, touchDrag: false },
						1200: { items: 1, mouseDrag: false, touchDrag: false }
					}
				}).on('resize.owl.carousel', function(elem) {
					setTimeout(function() {
						$slider.find('.owl-item.active')
							.next()
							.removeClass('active')
							.find('.media__image')
							.css({
								'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
							});
						$slider.find('.owl-item.active')
							.find('.media__image')
							.css({
								'transform': 'translate3d(0px, 0px, 0px)',
							});
						if ($slider.find('.owl-item.active').prev()) {
							$slider.find('.owl-item.active')
								.prev()
								.find('.media__image')
								.css({
									'transform': 'translate3d(' + imageTranslate + 'px, 0px, 0px)',
								});
						}
					}, 700);
				}).on('changed.owl.carousel', function(elem) {
					var item = elem.item.index;

					setTimeout(function() {
						translateSlideImages();
					}, 20);

					function translateSlideImages() {
						$slider.find('.owl-item.active')
							.prev()
							.find('.media__image')
							.css({
								'transform': 'translate3d(' + imageTranslate + 'px, 0px, 0px)',
								'transition': '1480ms',
							});

						$slider.find('.owl-item.active')
							.find('.media__image')
							.css({
								'transform': 'translate3d(0px, 0px, 0px)',
								'transition': '1480ms',
							});

						$slider.find('.owl-item.active')
							.next()
							.find('.media__image')
							.css({
								'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
								'transition': '1480ms',
							});
					}
				});

				setTimeout(function() {
					$slider.find('.owl-item.active')
						.next()
						.removeClass('active')
						.find('.media__image')
						.css({
							'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
						});
				}, 600);
		});


		$('.slider-callout').each(function(){
			var $slider = $(this);
			var $sliderSlides = $slider.find('.slider__slides');
			var imageTranslate = $(this).attr('data-imagetranslate');
			var currSlide = 0;
			var numOfSlides = $slider.find('.slider__col:nth-child(1) .slider__slide').length;

			$sliderSlides
				.addClass('owl-carousel')
				.owlCarousel({
					nav: false,
					loop: false,
					dots:false,
					items: 1,
					autoplay: false,
					autoPlaySpeed: 5000,
					autoPlayTimeout: 5000,
					autoplayHoverPause: false,
					smartSpeed: 800,
					responsive: {
						0: { items: 1, touchDrag: false, mouseDrag: false, smartSpeed: 600 },
						768: { items: 1, mouseDrag: false, touchDrag: false, smartSpeed: 1200 },
						1200: { items: 1, mouseDrag: false, touchDrag: false, smartSpeed: 1200 }
					}
				}).on('resize.owl.carousel', function(elem) {
					if (!mobileView()) {
						setTimeout(function() {
							$('.slider-callout .owl-item.active')
								.next()
								.add($('.slider-callout .owl-item.active').prev())
								.removeClass('active');

							$('.slider-callout .owl-item.active')
								.next()
								.find('.slider__slide-image')
								.css({
									'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
								});
							$('.slider-callout .owl-item.active')
								.prev()
								.find('.slider__slide-image')
								.css({
									'transform': 'translate3d(' + imageTranslate + 'px, 0px, 0px)',
								});
						}, 500);
					}
				}).on('changed.owl.carousel', function(elem) {
					if (!mobileView()) {
						var item = elem.item.index;

						$('#slider-callout-controls .owl-btn--prev')
							.add('#slider-callout-controls .owl-btn---next')
								.css('pointer-events', 'none');

						setTimeout(function() {
							$('.slider-callout .owl-item.active')
								.next().add($('.slider-callout .owl-item.active').prev())
								.removeClass('active')

							$('.slider-callout .owl-stage .owl-item:nth-child(' + (item + 1) + ')').addClass('active')
								.siblings().removeClass('active')

							translateSlideImages()
						}, 20);

						setTimeout(function() {
							currSlide = item;

							if (currSlide != 0) {
								$('#slider-callout-controls .owl-btn--prev').removeClass('disabled');
							}
							if (currSlide == 0) {
								$('#slider-callout-controls .owl-btn--prev').addClass('disabled');
							}
							if (currSlide != (numOfSlides - 1)) {
								$('#slider-callout-controls .owl-btn--next').removeClass('disabled');
							}
							if (currSlide == (numOfSlides - 1)) {
								$('#slider-callout-controls .owl-btn--next').addClass('disabled');
							}

							$('#slider-callout-controls .owl-btn--prev:not(.disabled)')
								.add('#slider-callout-controls .owl-btn--next:not(.disabled)')
									.css('pointer-events', 'all');
						}, 800);
					}
				});

				$('#slider-callout-controls .owl-btn--prev').addClass('disabled');

				function translateSlideImages() {
					$('.slider-callout .owl-item.active')
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(0px, 0px, 0px)',
							'transition': '780ms',
						});

					$('.slider-callout .owl-item.active')
						.prev()
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(' + imageTranslate + 'px, 0px, 0px)',
							'transition': '780ms',
						});

					$('.slider-callout .owl-item.active')
						.next()
						.find('.slider__slide-image')
						.css({
							'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
							'transition': '780ms',
						});
				}

				if (!mobileView()) {
					setTimeout(function() {
						$('.slider-callout .owl-item.active')
							.next()
							.removeClass('active')
							.find('.slider__slide-image')
							.css({
								'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
							});
					}, 600);
				}

				$('#slider-callout-controls .owl-btn--next').on('click', function(e) {
					e.preventDefault();

					$sliderSlides.trigger('next.owl.carousel');
				});

				$('#slider-callout-controls .owl-btn--prev').on('click', function(e) {
					e.preventDefault();
					$sliderSlides.trigger('prev.owl.carousel');
				});


				// if (!mobileView()) {
				// } else {
				// 	$('.slider-callout .owl-item.active .article__images').addClass('js-reveal-content').attr('data-type', 'slide-in')
				// 		.css({'transition-delay': '0s', 'transform': 'none', 'opacity': '1'});

				// 	setTimeout(function() {
				// 		// $sliderSlides.trigger('to.owl.carousel', 0);
				// 	}, 600);
				// }

		});
	}


	function clickHandlers() {

		// Navigation Menu

		$('.btn-menu').on('click', function(e) {
			e.preventDefault();

			$(this)
				.add($('body'))
				.toggleClass('nav-opened');

			if ($('body').hasClass('nav-opened')) {
				$('.nav-menu ul').addClass('js-reveal-content').attr('data-type', 'slide-in');
				addRevealAnimation();
				setTimeout(function() {
					$('.nav-menu li').css('transition-delay', '0s');
				}, 3000);
			} else {
				$('.nav-menu ul').removeClass('js-reveal-content');
				$('.nav-menu li').removeClass('in-view');
				$('.nav-menu li').css('transition-delay', 'unset');
			}
		});

		// Button Favourites

		$('.btn-fav').on('click', function(e) {
			e.preventDefault();

			$(this).toggleClass('active');

			if($(this).parents('.link--favourites').length){
				$(this).closest('.link--favourites').parent().hide();

				setTimeout(function() {
					var $links = $('.section--steps-subs-fav .section__body .row').children();
					var hidden = 0;

					for (var i = 0; i < $links.length; i++) {
						if ($($links[i]).css('display') == 'none') {
							hidden++;
						}
					}
					if (!mobileView()) {
						if(hidden > ($links.length - 2)) {
							$('.section--steps-subs-fav .section__body .row').hide();
						}
					} else {
						if(hidden > ($links.length - 1)) {
							$('.section--steps-subs-fav .section__body .row').hide();
						}
					}
				}, 300);
			}
		});

		// Button Subscribe

		var $lastClickedBtn;

		$('.jsToggleSubscribe').on('click', function(e) {
			e.preventDefault();
			var targetModal = $(this).attr('data-target');

			$lastClickedBtn = $(this);

			if (!$(this).hasClass('active')) {
				$(this).addClass('active');
			} else {
				$(targetModal).modal('show');
			}

		});

		$('.jsUnsubscribe').on('click', function(e) {
			e.preventDefault();

			$lastClickedBtn.removeClass('active');
			$($lastClickedBtn.attr('data-target')).modal('hide');
		});


		$('.jsToggleBtn').on('click', function(e) {
			e.preventDefault();
			$(this).closest('.jsToggleContainer').toggleClass('active').find('.jsToggleBody').slideToggle();
		});

		$('.nav-pages a').on('click', function(e) {
			e.preventDefault();
			$(this).parent().addClass('active').siblings().removeClass('active')
		});

		// Dropdowns

		$('.dropdown--button a').on('click', function (e){
			e.preventDefault();

			var text = $(this).text();
			var selected = $(this).closest('.dropdown-menu').siblings('.dropdown-toggle').find('span');
			selected.text(text);
		});

		$('.dropdown').on('show.bs.dropdown', function (){
			if($(this).parents('.nav-actions')) {
				$(this).parents('.nav-actions').addClass('dd-shown');
			}
		});

		$('.dropdown').on('hide.bs.dropdown', function () {
			if($(this).parents('.nav-actions')) {
				$(this).parents('.nav-actions').removeClass('dd-shown');
			}
		});

		// Audio Accordions
		$('.audio').not('.audio--current').find('.audio__body').hide()

		$('.audio').on('click', '.audio__head .btn-toggle', function (e) {
			e.preventDefault();

			$(this).parents('.audio__head').next().stop().slideToggle()
				.parent().toggleClass('audio--current')
				.siblings().removeClass('audio--current')
					.find('.audio__body').slideUp();

			if (!$('.audio--current').length && !$('.audio--playing').length) {
				setTimeout(function() {
					$('.audio-player').removeClass('active');
				}, 150);
			}
		});
	}

	function addRevealAnimation() {
		var winST = $win.scrollTop();
		var prevAnimated;
		var transitionDelay = 0.2;

		function init($elements) {
			$elements.each(function() {
				var $this = $(this);
				var visible = $this.data('visible');

				if (prevAnimated != null) {
					$this.css({'transition-delay': transitionDelay + 's'});
					transitionDelay += .1;
				}

				if (($win.scrollTop() + $win.height()) > $this.offset().top - 100) {
					$this.addClass('in-view');
				}

				prevAnimated = $this;
			});
		}

		init($('.js-reveal-content').children());
	}

	// Header Dropdowns

	function toggleDropdown(e) {
	  var _d = $(e.target).closest('.dropdown'),
	    _m = $('.dropdown-menu', _d);
	  setTimeout(function(){
	    var shouldOpen = e.type !== 'click' && _d.is(':hover');
	    _m.toggleClass('show', shouldOpen);
	    _d.toggleClass('show', shouldOpen);
	    $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
	  }, e.type === 'mouseleave' ? 300 : 0);
	}


	function hoverStates() {
		if (!tabletView()) {
			$('body')
				.on('mouseenter mouseleave','.nav-actions .dropdown',toggleDropdown)
				.on('click', '.dropdown-menu a', toggleDropdown);
		}

		// List Projects hover

		$('.jsHoverOne li > a').on('mouseenter', function() {
			var $this = $(this);
			$('.jsHoverOne li').addClass('not-hovered');
			$this.parent().removeClass('not-hovered');
		});
		$('.jsHoverOne li > a').on('mouseleave', function() {
			$('.jsHoverOne li').removeClass('not-hovered');
		});

		// Auto scrolling titles
		// -- Initial state
		$('.title-scroll').each(function () {
			const scrollTitleElement = $(this);
			const scrollTitleChild = $(scrollTitleElement.children().get(0));
			const elementWidth = scrollTitleElement.innerWidth();
			const childWidth = scrollTitleChild.innerWidth();
			// Ellipsize content
			if (childWidth > elementWidth) {
				scrollTitleChild.append('<span class="dots"></span>');
			}
		});
		// -- Handle mouse hover animation
		$('.title-scroll').on('mouseenter', function () {
			const scrollTitleElement = $(this);
			const scrollTitleChild = $(scrollTitleElement.children().get(0));
			const elementWidth = scrollTitleElement.innerWidth();
			const childWidth = scrollTitleChild.innerWidth();
			if (childWidth > elementWidth) {
				const speed = 0.08; // Change at will for the animation speed
				const delta = childWidth - elementWidth;
				const duration = delta / speed;
				scrollTitleChild.find('.dots').get(0).classList.add('invisible');
				scrollTitleChild.animate({
					'margin-left': `-${delta}px`
				}, duration, 'linear');
			}
		});
		// -- Handle normal state rollback
		$('.title-scroll').on('mouseleave', function() {
			const scrollTitleElement = $(this);
			const scrollTitleChild = $(scrollTitleElement.children().get(0));
			const elementWidth = scrollTitleElement.innerWidth();
			const childWidth = scrollTitleChild.innerWidth();
			scrollTitleChild.stop();
			scrollTitleChild.css({'margin-left':'0'});
			if (childWidth > elementWidth) {
				scrollTitleChild.find('.dots').get(0).classList.remove('invisible');
			}
		});
	}

	function revealOnScroll() {
		var winST = $win.scrollTop();
		var prevAnimated;
		var transitionDelay = 0.2;

         // Group Animations
         $('[data-group]').each(function() {
            var $this = $(this);
            var elemGroup = $this.data('group');

            if (elemGroup !== group) {
               index = 0;
               group = elemGroup;
            }

            $this
               .css({
                  'transitionDelay': '' + .2 * index++ + 's'
               });
         });

        $('.animate').each(function(e){
           var $animatedContainer = $(this);

           if($animatedContainer.offset().top < ($win.scrollTop() + $win.height() - 100)) {
              $animatedContainer.addClass('animated');
           }
        });

        $('.fade-on-load').each(function(e){
           var $animatedContainer = $(this);

          $animatedContainer.addClass('loaded');
        });


		function init($elements) {
			$elements.each(function() {
				var $this = $(this);
		        var delay = $this.parent().attr('data-delay');

                if (prevAnimated != null) {
                    if($this.offset().top == prevAnimated.offset().top) {
                    	if($this.parents('[class^="slider-"]')) {
	                        transitionDelay = .2;
	                        $this.css({'transition-delay': transitionDelay + 's'});
                    	} else {
	                        transitionDelay += 0.5;
	                        $this.css({'transition-delay': transitionDelay + 's'});
                    	}

                    } else {
                        transitionDelay = 0.2;
                        $this.css({'transition-delay': transitionDelay + 's'});
                    }
                }

				if (($win.scrollTop() + $win.height()) > $this.offset().top - 150) {
					if ( $this.parent().hasClass('delay') ) {
						setTimeout(function() {
							$this.addClass('in-view');
						}, delay);
					} else {
						$this.addClass('in-view');
					}
				}

            	if ($this.children().find('.list-actions-alt')) {
					$('.list-actions-alt').attr('style', "");
            	}

				prevAnimated = $this;
			});
		}

		init($('.js-reveal'));
		init($('.js-reveal-content').children());

		init($('.article__entry-inner > p'));
	}

	function initSlidersDesktop() {
		$('.slider-articles').each(function(){
			var $slider = $(this);
			var $sliderSlides = $slider.find('.slider__slides');
			$sliderSlides
				.addClass('owl-carousel')
				.owlCarousel({
					nav: true,
					loop: false,
					navText: ['<i class="ico-arrow-dark-left"></i>', '<i class="ico-arrow-dark"></i>'],
					navContainer: '.slider-articles .slider__arrows',
					autoplay: false,
					autoPlaySpeed: 5000,
					autoPlayTimeout: 5000,
					autoplayHoverPause: true,
					autoplayHoverPause: false,
					smartSpeed: 1200,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					responsive: {
						0: { items: 1, mouseDrag: false, touchDrag: false },
						768: { items: 1, mouseDrag: false, touchDrag: false },
						1200: { items: 1, mouseDrag: false, touchDrag: false }
					}
				}).on('changed.owl.carousel', function(elem) {
					$('.slider-articles .owl-prev').add('.slider-articles .owl-next').css('pointer-events', 'none');

					$('.slider-articles .article__content').addClass('js-reveal-content').attr('data-type', 'slide-in');

					$('.slider-articles .owl-item.active .article__image')
						.css({'transition': '.6s', 'transform': 'translateX(-104%)'});

					$('.slider-articles .owl-item:not(.active) .article__image')
						.css({'transition': '1s', 'transition-delay': '1s', 'transform': 'translateX(0%)'});

					setTimeout(function() {
						$('.slider-articles .article__content').addClass('in-view');
						$('.slider-articles .article__content > div').addClass('in-view').css('transition-delay', '0s');

						$('.slider-articles .owl-item:not(.active) .article__image')
							.css({'transition': '0s', 'transform': 'translateX(104%)'});
					}, 1200);

					setTimeout(function() {
						$('.slider-articles .owl-prev:not(.disabled)').add('.slider-articles .owl-next:not(.disabled)').css('pointer-events', 'all');
					}, 1800);
				});

				$('.slider-articles .owl-item:not(.active) .article__image')
					.css({'transition': '0s', 'transform': 'translateX(104%)'});
				$('.slider-articles .owl-item.active .article__image')
					.css({'transition': '0s', 'transform': 'translateX(0%)'});

				$('.slider-articles .owl-next').on('click', function() {
					$('.slider-articles .article__content').add($('.article-link')).add($('.slider-articles .article__content > div'))
						.removeClass('in-view').css('transition-delay', 'unset');
				});
				$('.slider-articles .owl-prev').on('click', function() {
					$('.slider-articles .article__content').add($('.article-link')).add($('.slider-articles .article__content > div'))
						.removeClass('in-view').css('transition-delay', 'unset');
				});
		});


		if (!mobileView()) {
			$('.slider-links').each(function(){
				var $slider = $(this);
				var $sliderSlides = $slider.find('.slider__slides');
				var sliderId = $(this).attr('id');
				var navContainer = '#' + sliderId + ' .slider__arrows';
				var dotsContainer = '#' + sliderId + ' .slider__dots';
				var slidesNum = $slider.attr('data-slidesnum');
				var imageTranslate = $(this).attr('data-imagetranslate');

				$sliderSlides
					.addClass('owl-carousel')
					.owlCarousel({
						nav: false,
						dots:false,
						loop: false,
						items: 1,
						autoplay: false,
						autoPlaySpeed: 5000,
						autoPlayTimeout: 5000,
						autoplayHoverPause: true,
						autoplayHoverPause: false,
						smartSpeed: 800,
						responsive: {
							0: { items: 1, mouseDrag: false, touchDrag: false },
							768: { items: 1, mouseDrag: false, touchDrag: false },
							1200: { items: 1, mouseDrag: false, touchDrag: false }
						}
					}).on('changed.owl.carousel', function(elem) {
						$slider.find('.owl-btn').css('pointer-events', 'none');

						var item = elem.item.index;

						$slider.find('.owl-item.active .link__content')
							.addClass('animate-out');

						$slider.find('.link__content')
							.removeClass('in-view')
							.css('transition-delay', '0s');

						setTimeout(function() {
							$slider.find('.owl-item.active')
								.next().add($slider.find('.owl-item.active').prev())
								.removeClass('active')

							$slider.find('.owl-stage .owl-item:nth-child(' + (item + 1) + ')').addClass('active')
								.siblings().removeClass('active')

							translateSlideImages()
						}, 20);

						setTimeout(function() {
							$slider.find('.owl-item.active .link__content')
								.removeClass('animate-out');

							$slider.find('.owl-item.active')
								.next()
									.find('.link__content')
									.removeClass('in-view');

							$slider.find('.owl-item.active .link__content').addClass('in-view').css('transition-delay', '0s');
						}, 300);

						setTimeout(function() {
							$slider.find('.owl-btn').css('pointer-events', 'all');
						}, 800);
					});

					setTimeout(function() {
						$slider.find('.owl-item.active')
							.next()
							.removeClass('active')
							.find('.link__image')
							.css({
								'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
							});
						$slider.find('.owl-carousel').removeClass('owl-hidden');
					}, 600);

					function translateSlideImages() {
						$slider.find('.owl-item.active')
							.find('.link__image')
							.css({
								'transform': 'translate3d(0px, 0px, 0px)',
								'transition': '780ms',
							});

						$slider.find('.owl-item.active')
							.prev()
							.find('.link__image')
							.css({
								'transform': 'translate3d(' + imageTranslate + 'px, 0px, 0px)',
								'transition': '780ms',
							});


						$slider.find('.owl-item.active')
							.next()
							.find('.link__image')
							.css({
								'transform': 'translate3d(' + -imageTranslate + 'px, 0px, 0px)',
								'transition': '780ms',
							});
					}

					$slider.find('.owl-btn--next').on('click', function(e) {
						e.preventDefault();
						var owlPage = $slider.find('.owl-dot-paging:nth-child(1) span').text();
						var nextPage = (parseInt(owlPage) + 1) < slidesNum ? parseInt(owlPage) + 1 : slidesNum;

						if (nextPage != slidesNum) {
							$slider.find('.owl-btn--next').removeClass('disabled');
						}
						if (nextPage == slidesNum) {
							$slider.find('.owl-btn--next').addClass('disabled');
							$slider.find('.owl-btn--prev').removeClass('disabled');
						}

						$slider.find('.owl-dot-paging:nth-child(1) span').text(nextPage);
						$sliderSlides.trigger('next.owl.carousel');
					});

					$slider.find('.owl-btn--prev').on('click', function(e) {
						e.preventDefault();
						var owlPage = $slider.find('.owl-dot-paging:nth-child(1) span').text();
						var prevPage = (parseInt(owlPage) - 1) <= 0 ? 1 : parseInt(owlPage) - 1;

						$slider.find('.owl-dot-paging:nth-child(1) span').text(prevPage);
						$sliderSlides.trigger('prev.owl.carousel');

						if (prevPage != 1) {
							$slider.find('.owl-btn--prev').removeClass('disabled');
						}
						if (prevPage == 1) {
							$slider.find('.owl-btn--prev').addClass('disabled');
							$slider.find('.owl-btn--next').removeClass('disabled');
						}
					});

				setTimeout(function() {
					$slider.find('.owl-dot-paging:nth-child(1) span').text(1);
					$slider.find('.owl-dot-paging:nth-child(2) span').text(slidesNum);
					$slider.find('.owl-btn--prev').addClass('disabled');
					$slider.find('.owl-btn--next').removeClass('disabled');
					$sliderSlides.trigger('to.owl.carousel', 0);
				}, 600);
			});
		} else {
			$('.slider-links .slider__slides')
				.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
			$('.slider-links .slider__slides')
				.find('.owl-stage-outer').children().unwrap();

			setTimeout(function() {
				$('.slider-links .link').children().removeClass('animate-out');
				$('.slider-links .link__image')
					.css({'transition-delay': '0s', 'transform': 'none'});
			}, 600);
		}
	}


	// Audio Players


	function calculateTotalValue(length) {
	  var minutes = Math.floor(length / 60),
	    seconds_int = length - minutes * 60,
	    seconds_str = seconds_int.toString(),
	    seconds = seconds_str.substr(0, 2),
	    time = minutes + ':' + seconds

	  return time;
	}

	function calculateCurrentValue(currentTime) {
	  var current_hour = parseInt(currentTime / 3600) % 24,
	    current_minute = parseInt(currentTime / 60) % 60,
	    current_seconds_long = currentTime % 60,
	    current_seconds = current_seconds_long.toFixed(),
	    current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);

	  return current_time;
	}

	function initAudioPlayers() {
		var audioPlayers = [];

		if ( $('.audio').length ) {

			$('.jsPlayFromStart').on('click', function(e) {
				e.preventDefault();
				var headerH = $('.header').outerHeight();

				$('html, body').animate({
					scrollTop: $('.audios').offset().top - headerH
				}, 1400);
				setTimeout(function() {
					$('.audio:nth-child(1)').find('.btn-play-pause').trigger('click');
				}, 1400);

				$('.audio-player').addClass('active first-audio');
			});

			$('.audio-player .btn-play-pause').on('click', function(e) {
				e.preventDefault();

				if ($(this).hasClass('active')) {
					$('.audios').find('.btn-play-pause.active').trigger('click');
				} else {
					$('.audios').find('.audio--current .btn-play-pause').trigger('click');
				}
			});

			$('.audio-player .btn-audio-prev').on('click', function(e) {
				e.preventDefault();

				var $current = $('.audios .btn-play-pause.active').length ? $('.audios .btn-play-pause.active').closest('.audio') : $('.audios .audio--current');

				if ($current.prev().length) {
					if (!$('.audios .btn-play-pause.active').length) {
						$current.prev().find('.btn-toggle').trigger('click');

			        	var titleText = $current.prev().find('.styleH5').text();
			        	var timeText = $current.prev().find('.start-time').text();

						$('.audio-player').find('.styleH6').text(titleText);
						$('.audio-player').find('.start-time').text('-' + timeText);

					} else {
						$current.prev().find('.btn-play-pause').trigger('click');
						$('.audio--current').siblings().removeClass('audio--playing');
					}
				} else {
					$('.audio-player').addClass('first-audio').removeClass('last-audio');
				}
			});

			$('.audio-player .btn-audio-next').on('click', function(e) {
				e.preventDefault();

				var $current = $('.audios .btn-play-pause.active').length ? $('.audios .btn-play-pause.active').closest('.audio') : $('.audios .audio--current');

				if ($current.next().length) {
					if (!$('.audios .btn-play-pause.active').length) {
						$current.next().find('.btn-toggle').trigger('click');

			        	var titleText = $current.next().find('.styleH5').text();
			        	var timeText = $current.next().find('.start-time').text();

						$('.audio-player').find('.styleH6').text(titleText);
						$('.audio-player').find('.start-time').text('-' + timeText);
					} else {
						$current.next().find('.btn-play-pause').trigger('click');
						$('.audio--current').siblings().removeClass('audio--playing');
					}
				} else {
					$('.audio-player').addClass('last-audio').removeClass('first-audio');
				}
			});

			function seek(event) {
				var progress = $('.audio-player').find('.seek-obj-container progress');
				var percent = event.offsetX / this.offsetWidth;
				var startTime = $('.audio--current').find('.start-time');
				// player[0].currentTime = percent * player[0].duration;

				$('.audio--current').find('.player')[0].currentTime = percent * $('.audio--current').find('.player')[0].duration;
				progress.value = percent / 100;

				$('.audio--current').each(function() {
					startTime.backward_timer({
					  seconds: $('.audio--current').find('.player')[0].duration - $('.audio--current').find('.player')[0].currentTime,
					  format: 'm%:s%'
					});
				});

				$('.audio-player').find('.start-time').backward_timer({
				  seconds: $('.audio--current').find('.player')[0].duration - $('.audio--current').find('.player')[0].currentTime,
				  format: '-m%:s%'
				});
			}

			$('.audio').each(function(){
				var $this = $(this);
				var thisId = $(this).attr('id');
				var $playAudio = $this.find('.btn-play-pause');
				var $durationDiv = $this.find('.end-time');
				var $player = $this.find('.player');
				var progressClone = $('.audio-player').find('.seek-obj-container');

				var audioDuration = calculateTotalValue($player[0].duration)
				$durationDiv[0].innerHTML = audioDuration;

				$this.find('.start-time').backward_timer({
				  seconds: $player[0].duration,
				  format: 'm%:s%'
				});

				var isPlaying = false;

		     	// Controls Listeners
				$player.on('timeupdate', function() {
					var player = $(this);
					var length = player[0].duration
					var current_time = player[0].currentTime;
					var endTime = player.closest('.audio').find('.end-time');
					var startTime = $('.audio--current').find('.start-time');
					var progress = $('.audio-player').find('.seek-obj-container progress');

					// calculate total length of value
					var totalLength = calculateTotalValue(length)
					endTime[0].innerHTML = totalLength;

					// calculate current value time
					var currentTime = calculateCurrentValue(current_time);
					// startTime[0].innerHTML = currentTime;

					progress[0].value = (player[0].currentTime / player[0].duration);
					progress.on('click', seek);

					if (player[0].currentTime == player[0].duration) {
						$('.btn-play-pause').removeClass('active');


						player.closest('.audio').next().find('.btn-play-pause').trigger('click');

						setTimeout(function() {
							startTime.backward_timer({
							  seconds: player[0].duration,
							  format: 'm%:s%'
							});

							player[0].currentTime = 0;
						}, 10);
					}
				});

		    	$playAudio.on('click', function(e) {
		        	e.preventDefault();
		        	var titleText = $(this).closest('.audio').find('.styleH5').text();

					audioPlayers.pop();
					audioPlayers.push($player);

					$('.player').not($player).each(function() {
						$(this).closest('.audio').find('.start-time').backward_timer('cancel');
						$(this)[0].pause();
					});

					$('.audio-player').addClass('active');
					$('.audio-player').find('.styleH6').text(titleText);

			        if ($player[0].paused === false) {
			        	$this.removeClass('audio--playing');
						$this.find('.start-time').backward_timer('cancel');
						$('.audio-player').find('.start-time').backward_timer('cancel');
			        	$player[0].pause();
			        	$playAudio.add('.audio-player .btn-play-pause').removeClass('active');
			        	isPlaying = false;

						if (!$('.audio--current').length && !$('.audio--playing').length) {
							setTimeout(function() {
								$('.audio-player').removeClass('active');
							}, 10);
						}
			        } else {
			        	$this.addClass('audio--playing');

						$this.find('.audio__head').next().stop().slideDown()
							.parent().addClass('audio--current')
							.siblings().removeClass('audio--current')
								.find('.audio__body').slideUp();

						$('.audio-player').find('.start-time').backward_timer({
						  seconds: $player[0].duration - $player[0].currentTime,
						  format: '-m%:s%'
						});
						$('.audio-player').find('.start-time').backward_timer('start');

						$this.find('.start-time').backward_timer({
						  seconds: $player[0].duration - $player[0].currentTime,
						  format: 'm%:s%'
						});
						$this.find('.start-time').backward_timer('start');

			        	$('.btn-play-pause').add('.audio-player .btn-play-pause').removeClass('active');

			        	$player[0].play();

			        	$playAudio.add('.audio-player .btn-play-pause').addClass('active');
			        	$playAudio.addClass('started-playing');
			        	setTimeout(function() {
			        		$playAudio.removeClass('started-playing');
			        	}, 1000);

			        	isPlaying = true;
			        }
		        });
			});
		}
	}


	function timelineEvents() {
		var $timelineNav    = $('.jsTimelineNav');
		var $dateBtnUp      = $timelineNav.find('.nav__btn--up');
		var $dateBtnDown    = $timelineNav.find('.nav__btn--down');
		var $navDates       = $timelineNav.find('.nav__dates ul');
		var $navDots        = $timelineNav.find('.nav__dots');
		var $navDot         = $timelineNav.find('.nav__dot');

		function scroll(sectionNum, section) {
			if (!section.length) {
				return;
			}
			$navDates.find('li[data-week="' + sectionNum + '"]')
				.addClass(activeClass).siblings().removeClass(activeClass);
			$navDots.find('[data-week="' + sectionNum + '"]')
				.addClass(activeClass).siblings().removeClass(activeClass);

			$('body').addClass('timeline-scroll').removeClass('header-down').addClass('header-up');

			$('html, body').animate({
				scrollTop: section.offset().top - 55
			}, 2000);

			setTimeout(function() {
				$('body').removeClass('timeline-scroll');
			}, 2000);

			return false;
		}

		// Nav Dots Click events
		$navDot.on('click', function(e){
			e.preventDefault();
			var scrollTo          = $(this).parent().attr('data-week');
			var sectionToScrollTo = $('.links-timeline[data-week="' + scrollTo + '"]');

			scroll(scrollTo, sectionToScrollTo);
		});

		// Date Buttons Click events
		$dateBtnDown.on('click', function(e){
			e.preventDefault();
			var $activeDate       = $navDates.find('.active');
			var scrollTo          = $activeDate.next().attr('data-week');
			var sectionToScrollTo = $('.links-timeline[data-week="' + scrollTo + '"]');

			scroll(scrollTo, sectionToScrollTo);
		});
		$dateBtnUp.on('click', function(e){
			e.preventDefault();
			var $activeDate       = $navDates.find('.active');
			var scrollTo          = $activeDate.prev().attr('data-week');
			var sectionToScrollTo = $('.links-timeline[data-week="' + scrollTo + '"]');

			scroll(scrollTo, sectionToScrollTo);
		});
	}


	var $actions = $('.list-actions-alt');
	var $actionsParent = $actions.parent();

	function articleActionsScroll() {

		if ($('.jsTextSizing').length) {
			var winH			   = $win.height();
			var winST	 		   = $win.scrollTop();
			var winB			   = (winST + winH);
			var winHalfH 		   = winH / 2;

			var $section 		   = $actionsParent;
			var sectionTop         = $section.offset().top;
			var sectionBottom      = sectionTop + $section.outerHeight();

			var pointToFix;

			if (mobileView()) {
				pointToFix = sectionTop - 40;
			} else {
				pointToFix = sectionTop - 40;
			}

			if (winST > sectionTop) {
				if (winST + $actions.outerHeight() + 40 > sectionBottom) {
					$('.article-large').addClass('list-actions-bottom').removeClass('list-actions-fixed');
				} else {
					$('.article-large').removeClass('list-actions-bottom').addClass('list-actions-fixed');
				}
			} else {
				$('.article-large').toggleClass('list-actions-fixed', winST >= pointToFix);
			}
		}
	}
	function timelineScroll() {
		if ($('.jsTimelineNav').length) {
			var winH			   = $win.height();
			var winST	 		   = $win.scrollTop();
			var winB			   = (winST + winH);
			var winHalfH 		   = winH / 2;

			var $timelineNav       = $('.jsTimelineNav');
			var $navDots           = $timelineNav.find('.nav__dots');
			var $navDates          = $timelineNav.find('.nav__dates ul');

			var $section 		   = $('.links-timeline');
			var $navContainer      = $('.section-timeline .section__inner');
			var navContainerTop    = $navContainer.offset().top;
			var navContainerBottom = navContainerTop + $navContainer.outerHeight();

			var pointToFix;

			if (mobileView()) {
				pointToFix = navContainerTop;
			} else {
				pointToFix = navContainerTop - 55;
			}

			if (winST > navContainerTop) {
				if ( winB < navContainerBottom) {
					$('body').addClass('nav-timeline-fixed').removeClass('nav-timeline-bottom');
				} else {
					$('body').removeClass('nav-timeline-fixed').addClass('nav-timeline-bottom');
				}
			} else {
				$('body').toggleClass('nav-timeline-fixed', winST >= pointToFix);
			}

			$section.each(function() {
				var $this      = $(this);
				var sTop       = $this.offset().top;
				var sHeight    = $this.outerHeight();
				var sData      = $this.attr('data-week');

				if ($('body').hasClass('timeline-scroll')) {
					return;
				}
				if ( winST + winHalfH > sTop ) {
					$navDots.find('[data-week="' + sData + '"]')
						.addClass(activeClass).siblings().removeClass(activeClass);

					$navDates.find('li[data-week="' + sData + '"]')
						.addClass(activeClass).siblings().removeClass(activeClass);
				}
			});

			var navDatesTop        = document.querySelector('.nav__dots .active').getBoundingClientRect().top;
			var roundedDotTop      = 0;

			if ($('body').hasClass('nav-timeline-fixed')) {
				roundedDotTop = Math.floor(navDatesTop);
				$('.nav-timeline .nav__dates').css({'marginTop': roundedDotTop + 'px'});
			}
			if ($('body').hasClass('nav-timeline-bottom')) {
				$('.nav-timeline .nav__dates').css({'marginTop': 0});
			}
			if ($('.nav__dots li:last-child').hasClass('active')) {
				$('.nav-timeline').addClass('last-date');
			} else {
				$('.nav-timeline').removeClass('last-date');
			}
			if ($('.nav__dots li:first-child').hasClass('active')) {
				$('.nav-timeline').addClass('first-date');
			} else {
				$('.nav-timeline').removeClass('first-date');
			}

		}
	}

	// Youtube API

    function youtubeAPI() {
		var players = [];

		if ( $('.video .video__media').length ) {

			$('.video .video__media').each(function(){
				var $this = $(this);
				var $playVideo = $this.siblings('.video__btn');
				var $parent = $this.closest('.video');
				var videoId = $this.data('videoid');
				var player = new YT.Player(this, {
					events: {
						onStateChange: function(data) {
							if (data.data == 0) {
								$parent.removeClass('active');
							}
						}
					},
					videoId: videoId
				});

				players.push(player);

				$playVideo.on('click', function(e) {
					e.preventDefault();
					player.playVideo();

					$parent
						.addClass('active')
				});

				$doc.on('click touchstart', function(e) {
					var $target = $(e.target);

					if( $('.video.active').length ) {
						if (!$target.closest('.video.active').length) {
							$parent.removeClass('active');

							$(player).each(function() {
								this.stopVideo();
							});
						}
					}
				});
			});
		}
    }

    function searchAutocomplete() {
		function autocomplete(inp, arr) {
		  var currentFocus;
		  inp.addEventListener("input", function(e) {
		      var a, b, i, val = this.value;
		      var placeholderDiv = document.createElement("DIV");
		      var placeholderVal = this.value;
		      var placeholderLetters = [];
			  var mixedLetters;

		      closeAllLists();
		      if (!val) { return false;}
		      currentFocus = -1;
		      a = document.createElement("DIV");
		      a.setAttribute("id", this.id + "autocomplete-list");
		      a.setAttribute("class", "autocomplete-items");

		      placeholderDiv = document.createElement("DIV");
		      placeholderDiv.setAttribute("class", "autocomplete-placeholder");

		      this.parentNode.appendChild(a);
		      this.parentNode.appendChild(placeholderDiv);

			  for (var j = 0; j < val.length; j++) {
				placeholderLetters.push(val[j])
			  }

		      for (i = 0; i < arr.length; i++) {

				if ($('.autocomplete-items').children().length) {
					placeholderVal = $('.autocomplete-items > div').find('input').val();

					placeholderDiv.innerHTML = '<span>' + placeholderVal + '</span>';

					for (var k = 0; k < placeholderVal.length; k++) {
						if (!(placeholderLetters[k] == placeholderVal[k])) {
							mixedLetters = placeholderLetters.join('');

							placeholderDiv.innerHTML = '<span>' + mixedLetters + placeholderVal.substr(mixedLetters.length) + '</span>';
						}
					}
				}

		        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {

		          b = document.createElement("DIV");
		          b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
		          b.innerHTML += arr[i].substr(val.length);
		          b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";

		          b.addEventListener("click", function(e) {
		              inp.value = this.getElementsByTagName("input")[0].value;
		              closeAllLists();
		          });

		          a.appendChild(b);
		        }
		      }
		  });

		  inp.addEventListener("keydown", function(e) {
		      var x = document.getElementById(this.id + "autocomplete-list");
		      if (x) x = x.getElementsByTagName("div");

		      if (e.keyCode == 40) {
		        currentFocus++;
		        addActive(x);
		      } else if (e.keyCode == 38) {
		        currentFocus--;
		        addActive(x);
		      } else if (e.keyCode == 13) {
		        e.preventDefault();
		        if (currentFocus > -1) {
		          if (x) x[currentFocus].click();
		        }
		        if (currentFocus == -1) {
		          if (x) x[0].click();
		        }
		      }
		  });
		  function addActive(x) {
		    if (!x) return false;
		    removeActive(x);
		    if (currentFocus >= x.length) currentFocus = 0;
		    if (currentFocus < 0) currentFocus = (x.length - 1);
		    x[currentFocus].classList.add("autocomplete-active");
		  }
		  function removeActive(x) {
		    for (var i = 0; i < x.length; i++) {
		      x[i].classList.remove("autocomplete-active");
		    }
		  }
		  function closeAllLists(elmnt) {
		    var x = document.getElementsByClassName("autocomplete-items");
		    for (var i = 0; i < x.length; i++) {
		      if (elmnt != x[i] && elmnt != inp) {
		        x[i].parentNode.removeChild(x[i]);
		      }
		    }
		    var placeholder = document.getElementsByClassName("autocomplete-placeholder");
		    for (var i = 0; i < placeholder.length; i++) {
		      if (elmnt != placeholder[i] && elmnt != inp) {
		        placeholder[i].parentNode.removeChild(placeholder[i]);
		      }
		    }
		  }
		  document.addEventListener("click", function (e) {
		      closeAllLists(e.target);
		  });
		}

		var autocompleteList = ["Frère Bernard", "Frère Bertrand", "Frère Benoit", "Frère Ben", "Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Canada","Cape Verde","Cayman Islands","Central Arfrican Republic","Chad","Chile","China","Colombia","Congo","Cook Islands","Costa Rica","Cote D Ivoire","Croatia","Cuba","Curacao","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Eritrea","Estonia","Ghana","Gibraltar","Greece","Greenland","Grenada","Guam","Guatemala","Guernsey","Guinea","Guinea Bissau","Guyana","Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Isle of Man","Israel","Italy","Jamaica","Japan","Jersey","Jordan","Kazakhstan","Kenya","Kiribati","Kosovo","Kuwait","Kyrgyzstan","Laos","Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein","Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Marshall Islands","Mauritania","Mauritius","Mexico","Micronesia","Moldova","Monaco","Mongolia","Montenegro","Montserrat","Morocco","Mozambique","Myanmar","Namibia","Nauro","Nepal","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","North Korea","Norway","Oman","Pakistan","Palau","Palestine","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion","Romania","Russia","Rwanda","Saint Pierre & Miquelon","Samoa","San Marino","Sao Tome and Principe","Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Slovakia","Slovenia","Solomon Islands","Somalia","South Africa","South Korea","South Sudan","Spain","Sri Lanka","St Kitts & Nevis","St Lucia","St Vincent","Sudan","Suriname","Swaziland","Sweden","Switzerland","Syria","Taiwan","Tajikistan","Tanzania","Thailand"];

		autocomplete($('.search__field')[0], autocompleteList);
	}

	//*
	// Copy URL to Clipboard
	//*

	$doc.on('click', '.js-copy', function(e) {
		e.preventDefault();

		var $this = $(this);

	    $this.addClass('active');

	    setTimeout(function() {
		    $this.addClass('animatePopup');
	    }, 1600);
	    setTimeout(function() {
		    $this.removeClass('active animatePopup');
	    }, 2400);

	    copyToClipboardMsg($('.js-copy-target').val(), 'msg');
	});

	function copyToClipboardMsg(elem, msgElem) {
		var succeed = copyToClipboard(elem);
	    var msg;

	    if (!succeed) {
	        msg = "Copy not supported or blocked.  Press Ctrl+c to copy."
	    } else {
	        msg = "Text copied to the clipboard."
	    }
	    if (typeof msgElem === "string") {
	        msgElem = document.getElementById(msgElem);
	    }
	    msgElem.innerHTML = msg;
	    setTimeout(function() {
	        msgElem.innerHTML = "";
	    }, 2000);
	}

	function copyToClipboard(text) {
		var $temp = $('<input>');
		$('body').find('.js-copy').parent().append($temp);

		$temp.val(text).select();

		var oldContentEditable = $temp.contentEditable,
		    oldReadOnly = $temp.readOnly,
		    range = document.createRange();

		$temp.contentEditable = true;
		$temp.readOnly = false;
		range.selectNodeContents($temp[0]);

		var s = window.getSelection();

	    var ua = window.navigator.userAgent;
	    var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
		    var targetId = "_hiddenCopyText_";
		    var elem = $('.js-copy-target');
		    var origSelectionStart, origSelectionEnd;

	        origSelectionStart = elem.selectionStart;
	        origSelectionEnd = elem.selectionEnd;

		    var currentFocus = document.activeElement;
		    $temp[0].setSelectionRange(0, 999999);

		    var succeed;
		    try {
		    	succeed = document.execCommand("copy");
		    } catch(e) {
		        succeed = false;
		    }
		    if (currentFocus && typeof currentFocus.focus === "function") {
		        currentFocus.focus();
		    }
		    return succeed;
        } else {
			s.removeAllRanges();
			s.addRange(range);

			$temp[0].setSelectionRange(0, 999999);

			$temp.contentEditable = oldContentEditable;
			$temp.readOnly = oldReadOnly;

			document.execCommand('copy');
			$temp.remove();

			if ($win.outerWidth() < 768) {
				$('.js-copy-target').focus().blur();
			}
        }
	}

	$doc.ready(function() {
		hoverStates();
		clickHandlers();
		initSliders();
		revealOnScroll();
		timelineEvents();
		searchAutocomplete();

		if ($('body.show-modal-newsletter').length) {
			$('.jsNewsletterModal').modal('show');
		}
		if ($('body.show-modal-newsletter-entry').length) {
			$('.jsNewsletterEntryModal').modal('show');
		}
		if ($('body.show-modal-commentaries').length) {
			$('.jsCommentariesModal').modal('show');
		}
		if ($('body.show-modal-commentaries-actions').length) {
			$('.jsCommentariesActionsModal').modal('show');
		}

		//Custom Select
		$('.form select').selectric({
			maxHeight: 177,
			onInit: function() {
				$('.selectric-items .selectric-scroll').mCustomScrollbar();
			},
			disableOnMobile: false,
			nativeOnMobile: false
		});

		$('.selectric-items .selectric-scroll').on('click', function(e) {
			$(this).closest('.form-select').find('select').selectric('open')
		});

		if (is_safari) {
			$('body').addClass('safari');
		}
		if (is_explorer) {
			$('body').addClass('ie');

			$('.animate').each(function(){
				$(this).addClass('animated');
			});
	        $('.fade-on-load').each(function(){
	          $(this).addClass('loaded');
	        });
	        $('.js-reveal').each(function(){
	        	$(this).addClass('in-view');
	        });
	        $('.js-reveal-content').each(function(){
	        	$(this).children().addClass('in-view');
	        });
	        $('.article__entry-inner > p').each(function(){
	        	$(this).addClass('in-view');
	        });
		}
	});

	$win
		.on('load', function() {
			initAudioPlayers();
			youtubeAPI();

			// Isotope Layout
			if ($isoGrid.length) {
				var elem = $isoGrid.get(0);

				iso = new Isotope( elem, {
					itemSelector: '.calendar__item',
					horizontalOrder: true,
					originLeft: true,
					originTop: true,
					sortBy: '[data-number]',
					resizable: false
				});
			}


		    // Magnific Popup
	    	$('.js-popup-video').magnificPopup({
				type: 'iframe',
				mainClass: 'mfp-anim-in',
			});
		})
		.on('resize', function() {
			if ($isoGrid.length) {
				// iso.destroy();

			}
			if (!is_explorer) {
				if ($win.scrollTop() > 0) {
					setTimeout(revealOnScroll, 600);
				}
			}

			if ( $('.jsTextSizing').length ) {
				$('.article-large').removeClass('list-actions-bottom').removeClass('list-actions-fixed');
				articleActionsScroll();
			}
		})
		.on('load resize orientationchange', function() {
			clearTimeout(initSM);
			initSM = setTimeout(initSlidersDesktop, 500);

			if (!mobileView()) {
				$('.jsToggleBody').show();
				$('.jsToggleContainer').removeClass('active');

				$('body.header-transparent-mobile').find('.header').addClass('header--black');
			} else {
				if (!$('.jsToggleContainer').hasClass('active')) {
					$('.jsToggleBody').hide();
				}

				$('body.header-transparent-mobile').find('.header').removeClass('header--black');
			}

			if ( $('.jsTextSizing').length ) {
				if ($('.article__entry--with-video').length) {
					if (mobileView()) {
						$('.article-large').find('.video--block').insertAfter('.testimonial-alt');
					} else {
						$('.article-large').find('.video--block').insertAfter('.list-actions-alt');
					}
				}
			}
		})
		.on('load scroll', function() {
			fixedHeader();
			timelineScroll();
			articleActionsScroll();
		})
		.on('scroll', function() {
			if (!is_explorer) {
				revealOnScroll();
			}
		});


})(window, document, window.jQuery);
